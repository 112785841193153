<template>
  <b-row>
    <b-col md="2" sm="4" class="my-1">
      <b-form-group class="mb-0">
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" class="w-50" />
      </b-form-group>
    </b-col>
    <b-col md="4" sm="8" class="my-1">

    </b-col>
    <b-col md="6" class="my-1">
      <b-form-group label="Filter" label-cols-sm="3" label-align-sm="right" label-size="sm" label-for="filterInput"
        class="mb-0">
        <b-input-group size="sm">
          <b-form-input id="filterInput" v-model="filter" type="search" placeholder="Type to Search" />
          <b-input-group-append>
            <b-button :disabled="!filter" @click="filter = ''">
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col cols="12">
      <b-table  responsive :per-page="perPage" :current-page="currentPage" :items="items" :fields="fields"
        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
        :filter-included-fields="filterOn" @filtered="onFiltered">
        <template #cell(hari_tanggal)="data">
          <b-row v-if="data.item.nama_kordinat == null " style="margin-left: -40px;margin-right: -110px; ">
            <b-col md="12">
              <b-card style=" ">
                <b-row style=" ">
                  <b-col md="12">
                    <b-alert variant="warning" show>
                      <div class="alert-body">
                        <h4>{{ data.item.nm_hari}}, {{ data.item.tgl_presensi }}</h4>
                        <span><strong>Pada hari ini user tidak memiliki aktifitas presensi</strong></span>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="data.item.nama_kordinat != null " style="margin-left: -30px;;margin-right: -110px;  background: #f7f6f6; padding: 10px;">
            <b-col md="12">  
                    
              <b-card style=" ">
                <b-row style=" ">
                  <b-col md="5">
                    <h5><b-avatar size="20px" rounded=""  >
                        <feather-icon icon="CalendarIcon" size="25" />
                      </b-avatar>
                      
                      {{ data.item.nm_hari}}, {{ data.item.tgl_presensi }}</h5>
                    <h6>
                      <b-avatar size="20px" rounded="" variant="light-info" class="mr-1">
                        <feather-icon icon="MapIcon" size="15" />
                      </b-avatar><b>Lokasi {{  data.item.nama_kordinat }}</b> <small></small>
                    </h6>
                    <a :href="'https://www.google.com/maps/dir/' + data.item.kordinat +'/'+ data.item.kordinat_masuk + '/@'+data.item.kordinat_masuk "
                      target="_blank">
                      <b-avatar size="20px" rounded="" variant="light-info" class="mr-1" v-if="data.item.st_kor_masuk !='Diluar radius presensi'" >
                        <feather-icon icon="RadioIcon" size="15" />
                      </b-avatar>

                      <b-avatar size="20px" rounded="" variant="light-danger" class="mr-1" v-if="data.item.st_kor_masuk =='Diluar radius presensi'" >
                        <feather-icon icon="RadioIcon" size="15" />
                      </b-avatar>
                      
                      
                      <small> Radius Presensi Masuk <b>{{ data.item.radius_masuk }} KM</b> Dari Central
                        Koordinat 
                      </small> 
                      <br>
                      

                    </a>
                    <a :href="'https://www.google.com/maps/dir/' + data.item.kordinat +'/'+ data.item.kordinat_pulang + '/@'+data.item.kordinat_masuk_pulang"
                      target="_blank" v-if=" data.item.jam_pulang != null ">
                      <b-avatar size="20px" rounded="" variant="light-info" class="mr-1" v-if="data.item.st_kor_pulang !='Diluar radius presensi'" >
                        <feather-icon icon="RadioIcon" size="15" />
                      </b-avatar>

                      <b-avatar size="20px" rounded="" variant="light-danger" class="mr-1" v-if="data.item.st_kor_pulang =='Diluar radius presensi'" >
                        <feather-icon icon="RadioIcon" size="15" />
                      </b-avatar>
                        <small> Radius Presensi Pulang <b>{{ data.item.radius_pulang }} KM</b> Dari Central
                        Koordinat
                      
                        </small> 
                    </a>
                  </b-col>
                  <b-col md="4">
                    <h6>Presensi Kerja</h6>
                    <small>
                      <b-avatar size="20px" rounded="" variant="light-info" class="mr-1">
                        <feather-icon icon="MapIcon" size="15" />
                      </b-avatar>Masuk <b>{{ data.item.jam_masuk }}</b>,<span v-show="data.item.jam_pulang != null "> Pulang <b>{{ data.item.jam_pulang }}
                      </b>(<b>{{data.item.durasi_kerja}} Jam Bekerja</b>)</span>
                    </small> <br>
                    <b-badge variant="light-warning" v-if="data.item.status_presensi =='Terlambat'"  class="d-block mb-1">
                     Terlambat Presensi Masuk
                    </b-badge>
                    <b-badge variant="light-primary" v-if="data.item.st_durasi =='Terpenuhi'"  class="d-block">
                      Durasi jam kerja terpenuhi >= {{ profilUser.durasi_kerja }} jam
                    </b-badge>

                    <b-badge variant="light-danger" v-if="data.item.st_durasi !='Terpenuhi'"  class="d-block">
                      Jam Kerja Tidak Terpenuhi kurang dari {{ profilUser.durasi_kerja }} Jam kerja </b-badge>
                  </b-col>

                  <b-col md="3"> 
                    <b-button   block variant="primary" @click="detailData(data.item)"
                      v-b-modal.detailPekerjaan>
                      <feather-icon icon="ListIcon" size="15" class="mr-1" /> Detail Prrsensi
                    </b-button>
                    <!-- <b-button   block variant="info">
                      <feather-icon icon="DownloadIcon" size="15" class="mr-1" />Dowload Presensi
                    </b-button> -->
                  </b-col>



                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </template>


        <!-- <template #cell(status)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template> -->
      </b-table>
    </b-col>
    <b-col cols="6"> 
    </b-col>
    <b-col cols="6">
      <b-pagination v-model="currentPage" :total-rows="items.length" :per-page="perPage" align="right" size="sm"
        class="my-0" />
    </b-col>
  </b-row>
</template>

<script>
  import {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BAlert
  } from 'bootstrap-vue'

  export default {
    components: {
      BTable,
      BAlert,
      BAvatar,
      BBadge,
      BRow,
      BCol,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
    },
    props: {
      items: {},
      profilUser : {}
    },
    emits :['detailData'],
    data() {
      return {
        perPage: 5,
        pageOptions: [5, 10, 15, 40],
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        fields: [{
            key: 'hari_tanggal',
            label: 'Histori Presensi '
          },
          {
            key: 'status',
            label: ''
          }


        ]
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({
            text: f.label,
            value: f.key
          }))
      },
    },
    mounted() {
      // Set the initial number of items
      this.totalRows = this.items.length
    },
    methods: {
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      detailData( presensi){
        this.$emit('detailData', presensi)
      }
    },
  }
</script>
  