<template>
    <div>
        <div class="card">
            <div class=" statistics-body">
                <div class="row p-1">
                    <div class="col-xl-4 col-sm-6 col-12 ">
                        <div class="media">
                            <b-avatar size="50px" variant="light-warning" rounded="">
                                <feather-icon icon="GridIcon" size="20" />
                            </b-avatar>
                            <div class="media-body my-auto ml-1">
                                <h6 class="font-weight-bolder mb-0">Kelompok {{ profilUser.nama_kelompok}}</h6>
                                <p class="card-text font-small-3 mb-0">{{ profilUser.nama_bidang}}</p>
                                <!-- <p class="card-text font-small-3 mb-0">Durasi kerja <b>{{ profilUser.durasi_kerja}} </b>Jam</p> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12 ">
                        <div class="media">
                            <b-avatar size="50px" variant="light-primary" rounded="">
                                <feather-icon icon="UserCheckIcon" size="20" />
                            </b-avatar>
                            <div class="media-body my-auto ml-1">
                                <h6 class="font-weight-bolder mb-0">{{ profilUser.nm_lengkap }}</h6>
                                <p class="card-text font-small-3 mb-0">{{ profilUser.nama_jabatan }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 col-12 ">
                        <div class="media">
                            <b-avatar size="50px" variant="light-info" rounded="">
                                <feather-icon icon="CalendarIcon" size="20" />
                            </b-avatar>
                            <div class="media-body my-auto ml-1">
                                <h6 class="font-weight-bolder mb-0">Histori Presensi </h6>
                                <p class="card-text font-small-3 mb-0"><b>{{tanggal}}</b></p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-2 col-sm-6 col-12 mb-2 mb-sm-0">
                        <router-link to="/laporan_perkelompok_detail/">
                            <b-button   variant="warning"
                                style="float: right;">
                                <feather-icon icon="ChevronsLeftIcon" class="mr-50" />
                                <span class="align-middle">Kembali</span>
                            </b-button>
                        </router-link>
                    </div>



                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BRow,
        BCol,
        BCard,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BInputGroupAppend,
        BFormGroup,
        BFormTextarea,
        BPagination,
        BButton,
        BAvatar,
        BBadge,
        BModal,
        VBModal,
        BAlert
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    export default {

        components: {
            BRow,
            BCol,
            BCard,
            BInputGroup,
            BFormInput,
            BInputGroupPrepend,
            BInputGroupAppend,
            BFormGroup,
            BFormTextarea,
            BPagination,
            BButton,
            BAvatar,
            BBadge,
            BModal,
            VBModal,
            BAlert
        },
        props: {
            profilUser: {},
            tanggal: {}
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        data() {
            return { 
                tgl_laporan : new Date(this.tgl)
            }
        },
        mounted() {
            this.tgl_now() 
        },

        methods: {  
        }

    }
</script>

<style>

</style>