<template>
  <div>

    <profil-user :profilUser="profilUser" :tanggal=tanggal></profil-user>
    <!-- <b-row class="mb-1">
      <b-col md="6"></b-col>
      <b-col md="6"> 
        <b-input-group>
          <b-form-input placeholder="input pencarian" v-model="cari" @input="cariData()" />
          <b-input-group-append>
            <b-button variant="outline-primary">
              <feather-icon icon="SearchIcon" /> Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row> -->

    <b-row>
      <b-col md="12">
        <b-card>  
          <list-presensi-vue :items="rsPresensi.rs" :profilUser="profilUser" @detailData =detailData></list-presensi-vue>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row>
      <b-col md="6">
        Total Row {{ items.lenght}}
      </b-col>
      <b-col md="6">
        <b-pagination v-model="currentPage" hide-goto-end-buttons :per-page='4' :total-rows="items.lenght" align="right"
          @input="pageNext(currentPage)" />
      </b-col>
    </b-row> -->

    <detailpekerjaan :detailPresensi = 'detailPresensi' :profilUser="profilUser"></detailpekerjaan>
  </div>
</template>

<script>
  import axios from '@/config/Axios'
  import Base from '@/config/Mixins_base'
  import {
    BRow,
    BCol,
    BCard,
    BModal,
    VBModal,
    BAlert
  } from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
  import Detailpekerjaan from './detailpekerjaan.vue'
  import profilUser from './profilUser.vue'
  import listPresensiVue from './listPresensi.vue'

  export default {
    mixins: [Base],
    components: {
      BRow,
      BCol,
      BCard,
      BModal,
      VBModal,
      BAlert,
      Detailpekerjaan,
      profilUser,
      listPresensiVue
    },
    data() {
      return {
        id_anggota: "",
        date: "",
        user_key: "",
        profilUser: {},
        rsPresensi: {},
        tanggal: "",
        detailPresensi : {}
      }
    },
    mounted() {
      this.cek_token();
      this.id_anggota = this.$route.params.id_anggota,
        this.date = this.$route.params.date,
        this.user_key = this.$route.params.user_key,
        this.loda_profil()
      this.load_histori_presensi(),
        this.tgl_now();
    },
    methods: {
      tgl_now() {
        const nm_bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus',
          'September', 'Oktober', 'November', 'Desember'
        ]
        const ddk = new Date(this.date)
        this.tanggal = 'Bulan ' + nm_bulan[ddk.getMonth()] + ', ' + ddk.getFullYear();
      },

      async loda_profil() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/kelompok/profil_user',
            data: {
              id_anggota: self.$route.params.id_angota,
              tgl: self.$route.params.date
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.profilUser = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },
      async load_histori_presensi() {
        const self = this;
        await axios({
            method: 'PUT',
            url: '/api/laporan/kelompok/load_histori_presensi',
            data: {
              id_anggota: self.$route.params.id_angota,
              tanggal: self.$route.params.date
            },
            headers: {
              'Authorization': self.isAuthenticated
            }
          })
          .then(function (response) {
            self.rsPresensi = response.data.result;
          }).catch(err => {
            self.pesan = err.message;
            self.notification('warning', "Error", err.message);
          });
      },

      detailData(presensi){
        this.detailPresensi = presensi
      }
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },


  }
</script>

<style>

</style>